import firebase from "gatsby-plugin-firebase";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HomeAppBar from "../components/HomeAppBar";
import React from "react";


import { Link } from "gatsby";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Card, Dialog, Input, Switch,  } from "@material-ui/core";
import axios from "axios";


class SignUpScreen extends React.Component {

    state ={
        email:"",
        password:"",
        cPassword:"",
        showCongrats:false,
        showError:false
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    enableSignUpButton(){
        if(this.validateEmail(this.state.email)){
            if(this.state.password.length > 6){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }
    }

    

    render(){
        
       return(
           <div style={
               {
                   display:"flex",
                    width: "100%",
                    height:"100%",
                    backgroundColor:"#ff7800",
                    justifyContent:"center",
                    alignItems:"center",
                    position:"absolute",
                    top:0,
                    bottom:0,
                    left:0,
                    right:0
               }
           }>
        <Card style={{borderRadius:10}}>
            <img style={{maxWidth:300}} src="https://www.lapingpong.com/wp-content/uploads/2015/11/LATTF-header2.png" />
            <Table>
                <TableBody style={{padding:10}}>
                    <TableRow >
                        <TableCell>
                            <Input fullWidth   placeholder="Email" onChange={(event)=>{
                                this.setState({email: event.target.value})}} ></Input>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Input type="password" fullWidth placeholder="Password" onChange={(event)=>{
                                this.setState({password: event.target.value})}} ></Input>
                        </TableCell>
                    </TableRow>
                 
                    <TableRow>
                        <TableCell>
                            <Button style={{backgroundColor: !this.enableSignUpButton() ? "gray":"#ff7800", color:"black"}} disabled={!this.enableSignUpButton()} onClick={()=>{
                                console.log("clicked")
                                
                                firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((user) =>{
                                    if(user.user){
                                        
                                        //this.setState({showCongrats:true})
                                        window.location.href = "/viewseasons/"
                                    }
                                }).catch((err)=>{
                                    this.setState({showError:true, error: err})
                                })
                            }} >Sign In</Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
        <Dialog open={this.state.showCongrats}>
        <div style={{margin:20}}>
            <h2>You're user account has successfully been created!</h2>
                    <p>Please download the mobile app from the Apple App Store or the Google Play Store, to keep score and live stream matches!</p>
</div>
        </Dialog>
        { this.state.showError ? 
        <Dialog open={this.state.showError}>
            <div style={{margin:20}}>

                    <p>{this.state.error.message}</p>
            </div>
        </Dialog>
        :null
    }
        
        </div>
    ) 
    }
    
}
export default SignUpScreen